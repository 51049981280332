export const data = [
    {
        "KG neu": 1,
        "Name der MAV": "Boxberg-Ahorn",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-boxberg.de",
        "DekanatNr": "Tauberbischofsheim",
        "Strasse": "Kapellenstraße 33",
        "PLZ": 74744,
        "Ort": "Ahorn-Eubigheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 1,
        "Name der MAV": "Grünsfeld-Wittighausen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav.gruensfeld@web.de",
        "DekanatNr": "Tauberbischofsheim",
        "Strasse": "Am oberen Tor 2",
        "PLZ": 97957,
        "Ort": "Wittighausen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 1,
        "Name der MAV": "Krautheim-Ravenstein-Assamstadt",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-kras.de",
        "DekanatNr": "Tauberbischofsheim",
        "Strasse": "Pfarrgasse 3",
        "PLZ": 74238,
        "Ort": "Krautheim-Gommersdorf",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 1,
        "Name der MAV": "Lauda-Königshofen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-lk@web.de",
        "DekanatNr": "Tauberbischofsheim",
        "Strasse": "Zähringer Straße 15",
        "PLZ": 97922,
        "Ort": "Lauda-Königshofen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 2,
        "Name der MAV": "Freudenberg",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-se-freudenberg@web.de",
        "DekanatNr": "Tauberbischofsheim",
        "Strasse": "Wiesenweg 5",
        "PLZ": 97896,
        "Ort": "Freudenberg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 2,
        "Name der MAV": "Großrinderfeld-Werbach",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-grossrinderfeld@web.de",
        "DekanatNr": "Tauberbischofsheim",
        "Strasse": "Beuth 4",
        "PLZ": 97950,
        "Ort": "Großrinderfeld",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 2,
        "Name der MAV": "Königheim",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-königheim.de",
        "DekanatNr": "Tauberbischofsheim",
        "Strasse": "Brehmestraße 18",
        "PLZ": 97953,
        "Ort": "Königheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 2,
        "Name der MAV": "Külsheim-Bronnbach",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav.kuelsheim.bronnbach@gmail.com",
        "DekanatNr": "Tauberbischofsheim",
        "Strasse": "Kirchbergweg 5",
        "PLZ": 97900,
        "Ort": "Külsheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 2,
        "Name der MAV": "Tauberbischofsheim",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav.tbb@gmail.com",
        "DekanatNr": "Tauberbischofsheim",
        "Strasse": "Rathauspl. 6",
        "PLZ": 97941,
        "Ort": "Tauberbischofshaeim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 2,
        "Name der MAV": "Wertheim",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-wertheim.se@gmx.de",
        "DekanatNr": "Tauberbischofsheim",
        "Strasse": "Johannes-Kerer-Straße 7",
        "PLZ": 97877,
        "Ort": "Wertheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 3,
        "Name der MAV": "Adelsheim-Osterburken-Seckach",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@se-aos.de",
        "DekanatNr": "Mosbach-Buchen",
        "Strasse": "Kreuzstraße 12",
        "PLZ": 74706,
        "Ort": "Osterburken",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 3,
        "Name der MAV": "Buchen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-buchen@web.de",
        "DekanatNr": "Mosbach-Buchen",
        "Strasse": "Dürmerstraße 34",
        "PLZ": 74722,
        "Ort": "Buchen-Hainstadt",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 3,
        "Name der MAV": "Hardheim-Höpfingen \nim Madonnenland",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@se-madonnenland.de",
        "DekanatNr": "Mosbach-Buchen",
        "Strasse": "Schlossplatz 4",
        "PLZ": 74736,
        "Ort": "Hardheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 3,
        "Name der MAV": "Mudau",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-mudau.de",
        "DekanatNr": "Mosbach-Buchen",
        "Strasse": "Langenelzer Straße 15",
        "PLZ": 69427,
        "Ort": "Mudau",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 3,
        "Name der MAV": "Walldürn",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@se-wallduern.de",
        "DekanatNr": "Mosbach-Buchen",
        "Strasse": "Neusasser Straße 10",
        "PLZ": 74731,
        "Ort": "Walldürn",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 4,
        "Name der MAV": "Billigheim-Neudenau-Schefflenz",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-billigheim-neudenau@web.de",
        "DekanatNr": "Mosbach-Buchen",
        "Strasse": "Hauptstraße 77",
        "PLZ": 74842,
        "Ort": "Billigheim-Sulzbach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 4,
        "Name der MAV": "Mosbach-Elz-Neckar Mose",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-mose.de",
        "DekanatNr": "Mosbach-Buchen",
        "Strasse": "Pfalzgraf-Otto-Straße 6",
        "PLZ": 74821,
        "Ort": "Mosbach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 4,
        "Name der MAV": "Neckartal-Hoher Odenwald Edith Stein",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-edith.stein@gmx.de",
        "DekanatNr": "Mosbach-Buchen",
        "Strasse": "Am Mittelberg 7",
        "PLZ": 69439,
        "Ort": "Zwingenberg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 5,
        "Name der MAV": "Mannheim Johannes XXIII.",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kathma-johannes23.de",
        "DekanatNr": "Mannheim",
        "Strasse": "Schwetzinger Straße 78",
        "PLZ": 68165,
        "Ort": "Mannheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 5,
        "Name der MAV": "Mannheim Maria Magdalena",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@ssemma.de",
        "DekanatNr": "Mannheim",
        "Strasse": "Geraer Ring 7",
        "PLZ": 68309,
        "Ort": "Mannheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 5,
        "Name der MAV": "Mannheim Nord",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kathma-nord.de",
        "DekanatNr": "Mannheim",
        "Strasse": "Speckweg 1",
        "PLZ": 68305,
        "Ort": "Mannheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 5,
        "Name der MAV": "Mannheim St. Martin",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-st.martin@st.martin-ma.de",
        "DekanatNr": "Mannheim",
        "Strasse": "Kolpingstraße 5",
        "PLZ": 68535,
        "Ort": "Edingen-Neckarshausen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 5,
        "Name der MAV": "Mannheim Süd",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-ma-sued.de",
        "DekanatNr": "Mannheim",
        "Strasse": "Frobeniusstraße 30",
        "PLZ": 68219,
        "Ort": "Mannheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 5,
        "Name der MAV": "Mannheim-Neckarstadt",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mavneckarstadt@kath-ma-neckarstadt.de",
        "DekanatNr": "Mannheim",
        "Strasse": "Max-Joseph-Straße 42",
        "PLZ": 68167,
        "Ort": "Mannheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 6,
        "Name der MAV": "Kath. Stadtkirche Heidelberg",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-hd.de",
        "DekanatNr": "Heidelberg-Weinheim",
        "Strasse": "Buchwaldweg 2",
        "PLZ": 69126,
        "Ort": "Heidelberg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 7,
        "Name der MAV": "Ladenburg-Heddesheim",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-se-ladenburg-heddesheim@web.de",
        "DekanatNr": "Heidelberg-Weinheim",
        "Strasse": "Schulstraße 17",
        "PLZ": 68542,
        "Ort": "Heddesheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 7,
        "Name der MAV": "Weinheim-Hirschberg",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "gabriele.andes@se-wh.de",
        "DekanatNr": "Heidelberg-Weinheim",
        "Strasse": "Forelenweg 2",
        "PLZ": 69469,
        "Ort": "Weinheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 8,
        "Name der MAV": "Bad Rappenau - Obergimpern",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-badrappenau.de",
        "DekanatNr": "Kraichgau",
        "Strasse": "Salinenstraße 13",
        "PLZ": 74906,
        "Ort": "Bad Rappenau",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 8,
        "Name der MAV": "Eppingen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-eppingen@t-online.de",
        "DekanatNr": "Kraichgau",
        "Strasse": "Talstraße 36-38",
        "PLZ": 75031,
        "Ort": "Eppingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 9,
        "Name der MAV": "Leimen-Nußloch-Sandhausen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-kiga-st.elisabeth@gmx.de",
        "DekanatNr": "Wiesloch",
        "Strasse": "Wendelinusstraße 5",
        "PLZ": 69207,
        "Ort": "Sandhausen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 9,
        "Name der MAV": "Walldorf-St. Leon-Rot",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@walero.de",
        "DekanatNr": "Wiesloch",
        "Strasse": "Dannheckerstraße 46",
        "PLZ": 69190,
        "Ort": "Walldorf",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 9,
        "Name der MAV": "Wiesloch-Dielheim",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav1@kath-wiedie.de",
        "DekanatNr": "Wiesloch",
        "Strasse": "Wieslocher Straße 14",
        "PLZ": 69168,
        "Ort": "Wiesloch",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 10,
        "Name der MAV": "Schwetzingen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@seelsorgeeinheit-schwetzingen.de",
        "DekanatNr": "Wiesloch",
        "Strasse": "Mozartstraße 5",
        "PLZ": 68723,
        "Ort": "Oftersheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 11,
        "Name der MAV": "Bad Schönborn-Kronau",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-bad-schoenborn-kronau@web.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Pestalozzistraße 1",
        "PLZ": 76669,
        "Ort": "Bad Schönborn",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 11,
        "Name der MAV": "Bretten-Walzbachtal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-bretten-walzbachtal@kath-bretten.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Postweg 50",
        "PLZ": 75015,
        "Ort": "Bretten",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 11,
        "Name der MAV": "Bruchsal St. Vinzenz",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "v.oberhof-mavbruchsal@web.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Tunnelstraße 26",
        "PLZ": 76646,
        "Ort": "Bruchsal",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 11,
        "Name der MAV": "Forst-Ubstadt-Weiher",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-forst-ubstadt-weiher@gmx.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Attholstraße 5 a",
        "PLZ": 76698,
        "Ort": "Ubstadt-Weiher",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 11,
        "Name der MAV": "Graben-Neudorf-Linkenheim",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mavsegraben-neudorf@web.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Fröbelstraße 1",
        "PLZ": 76676,
        "Ort": "Graben-Neudorf",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 11,
        "Name der MAV": "Karlsdorf-Neuthardt-Büchenau",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-kaneubue@web.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Gebrüder-Grimm-Straße 1",
        "PLZ": 76646,
        "Ort": "Bruchsal-Büchenau",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 11,
        "Name der MAV": "Kraichtal-Elsenz Hl. Geist",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "",
        "DekanatNr": "Bruchsal",
        "Strasse": "St. Martin Straße 14",
        "PLZ": 76703,
        "Ort": "Kraichtal",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 11,
        "Name der MAV": "Oberhausen-Philippsburg",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-seop.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Rheinstraße 27",
        "PLZ": 68794,
        "Ort": "Rheinhausen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 11,
        "Name der MAV": "Östringen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-oestringen@web.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Hauptstraße 68",
        "PLZ": 76684,
        "Ort": "Östringen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 11,
        "Name der MAV": "Stutensee-Weingarten",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-weistu.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Blumenstraße 3-5",
        "PLZ": 76297,
        "Ort": "Stutensee",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 11,
        "Name der MAV": "Waghäusel-Hambrücken",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-w-h.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Schanzenstraße 1a",
        "PLZ": 68753,
        "Ort": "Waghäusel",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 12,
        "Name der MAV": "Kämpfelbachtal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav_se_kaempfelbachtal@web.de",
        "DekanatNr": "Pforzheim",
        "Strasse": "Hauptstraße 35",
        "PLZ": 75236,
        "Ort": "Kämpfelbach-Bilfingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 12,
        "Name der MAV": "Pforzheim",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "micaela.constantin@kath-pforzheim.de",
        "DekanatNr": "Pforzheim",
        "Strasse": "Römerstraße 70",
        "PLZ": 75175,
        "Ort": "Pforzheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 13,
        "Name der MAV": "Karlsruhe Alb-Südwest St. Nikolaus",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@st-nikolaus-ka.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Speyerer Straße 26",
        "PLZ": 76199,
        "Ort": "Karlsruhe",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 13,
        "Name der MAV": "Karlsruhe Allerheiligen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@allerheiligen-ka.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Sophienstraße 236",
        "PLZ": 76185,
        "Ort": "Karlsruhe",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 13,
        "Name der MAV": "Karlsruhe Durlach-Bergdörfer",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-durlach-bergdoerfer.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Gänsbergstraße 3",
        "PLZ": 76228,
        "Ort": "Karlsruhe",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 13,
        "Name der MAV": "Karlsruhe Hardt",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav.ka-hardt@web.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Moldaustraße 16",
        "PLZ": 76149,
        "Ort": "Karlsruhe",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 13,
        "Name der MAV": "Karlsruhe Nord-Ost St. Raphael",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "st-raphael-ka@web.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Königsberger Straße 55",
        "PLZ": 76139,
        "Ort": "Karlsruhe",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 13,
        "Name der MAV": "Karlsruhe Südwest",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@se-ka-sw.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Wilhelm-Leuschner-Straße 32",
        "PLZ": 76189,
        "Ort": "Karlsruhe",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 14,
        "Name der MAV": "Ettlingen Land",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-ettlingen-land@web.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Rosenstraße 21 a",
        "PLZ": 76275,
        "Ort": "Ettlingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 14,
        "Name der MAV": "Ettlingen Stadt",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-ettlingen-stadt.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Augustin-Kast-Straße 6",
        "PLZ": 76275,
        "Ort": "Ettlingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 14,
        "Name der MAV": "Rheinstetten",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-rheinstetten@web.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Kreuzstraße 8",
        "PLZ": 76287,
        "Ort": "Rheinstetten",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 15,
        "Name der MAV": "Durmersheim-Au a. Rh.",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mavdurmersheim@web.de",
        "DekanatNr": "Rastatt",
        "Strasse": "Uhlandstraße 2",
        "PLZ": 76448,
        "Ort": "Durmersheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 15,
        "Name der MAV": "Iffezheim-Ried",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-iffezheim-ried.de",
        "DekanatNr": "Rastatt",
        "Strasse": "Lindenstraße 2",
        "PLZ": 76437,
        "Ort": "Iffezheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 15,
        "Name der MAV": "Rastatt",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mavrastatt3@web.de",
        "DekanatNr": "Rastatt",
        "Strasse": "Badstraße 1",
        "PLZ": 76437,
        "Ort": "Rastatt",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 15,
        "Name der MAV": "Südhardt-Rhein",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-suedhardt-rhein@web.de",
        "DekanatNr": "Rastatt",
        "Strasse": "Kirchstraße 35",
        "PLZ": 76467,
        "Ort": "Bietigheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 15,
        "Name der MAV": "Vorderes Murgtal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-vorderesmurgtal@web.de",
        "DekanatNr": "Rastatt",
        "Strasse": "Wörtelstraße 28 a",
        "PLZ": 76456,
        "Ort": "Kuppenheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 16,
        "Name der MAV": "Forbach-Weisenbach",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-forbach@web.de",
        "DekanatNr": "Rastatt",
        "Strasse": "Kirchplatz 14",
        "PLZ": 76596,
        "Ort": "Forbach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 16,
        "Name der MAV": "Gaggenau",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav.gaggenau@gmail.com",
        "DekanatNr": "Rastatt",
        "Strasse": "Pestalozzistraße 1",
        "PLZ": 76571,
        "Ort": "Gaggenau",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 16,
        "Name der MAV": "Gaggenau-Ottenau",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-ottenau@gmx.de",
        "DekanatNr": "Rastatt",
        "Strasse": "Hinterbachweg 1",
        "PLZ": 76571,
        "Ort": "Gaggenau",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 17,
        "Name der MAV": "Baden-Baden Mitte",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav.mitte@kath-baden-baden.de",
        "DekanatNr": "Baden-Baden",
        "Strasse": "Bernhardusplatz 10",
        "PLZ": 76530,
        "Ort": "Baden-Baden",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 17,
        "Name der MAV": "Baden-Baden-Oos",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-se-oos@kath-baden.de",
        "DekanatNr": "Baden-Baden",
        "Strasse": "Ooser Hauptstraße 8",
        "PLZ": 76532,
        "Ort": "Baden-Baden Oos",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 17,
        "Name der MAV": "Bühl - Vimbuch",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-buehl.de",
        "DekanatNr": "Baden-Baden",
        "Strasse": "Gleißlestraße 1",
        "PLZ": 77815,
        "Ort": "Bühl-Weitenung",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 17,
        "Name der MAV": "Ottersweier Maria Linden",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-ottersweier.de",
        "DekanatNr": "Baden-Baden",
        "Strasse": "Friedhofstraße 3",
        "PLZ": 77833,
        "Ort": "Ottersweier",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 17,
        "Name der MAV": "Sinzheim-Hügelsheim",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@seszhg.de",
        "DekanatNr": "Baden-Baden",
        "Strasse": "Kirchstraße 3 b",
        "PLZ": 76547,
        "Ort": "Sinzheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 18,
        "Name der MAV": "Achertal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-achertal.de",
        "DekanatNr": "Achern-Renchtal",
        "Strasse": "Schulstraße 30",
        "PLZ": 77876,
        "Ort": "Kappelrodeck",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 18,
        "Name der MAV": "Appenweier-Durbach",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@appenweier-durbach.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Hohenrainweg 11",
        "PLZ": 77767,
        "Ort": "Appenweier Durbach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 18,
        "Name der MAV": "Lauf-Sasbachtal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-lauf-sasbachtal@web.de",
        "DekanatNr": "Achern-Renchtal",
        "Strasse": "Kirchweg 8",
        "PLZ": 77887,
        "Ort": "Sasbachwalden",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 18,
        "Name der MAV": "Oberes Renchtal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-oberes-renchtal.de",
        "DekanatNr": "Achern-Renchtal",
        "Strasse": "Schulstraße 11 a",
        "PLZ": 77728,
        "Ort": "Oppenau",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 18,
        "Name der MAV": "Oberkirch",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "MAV@kath-oberkirch.de",
        "DekanatNr": "Achern-Renchtal",
        "Strasse": "St. Gabrielweg 2",
        "PLZ": 77704,
        "Ort": "Oberkirch",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 19,
        "Name der MAV": "Hanauerland",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-hanauerland.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Schillerstraße 2",
        "PLZ": 77731,
        "Ort": "Willstätt",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 19,
        "Name der MAV": "Kehl",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-kehl@gmx.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Gustav-Weis-Straße 2",
        "PLZ": 77694,
        "Ort": "Kehl",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 19,
        "Name der MAV": "Offenburg St. Ursula",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-offenburg.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Kittelgasse 24",
        "PLZ": 77652,
        "Ort": "Offenburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 19,
        "Name der MAV": "Schutterwald-Hohberg-Neuried",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-shn.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Hauptstraße 75",
        "PLZ": 77746,
        "Ort": "Schutterwald",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 19,
        "Name der MAV": "Vorderes Kinzigtal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kathvk.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Neudorfstraße 15",
        "PLZ": 77791,
        "Ort": "Berghaupten",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 20,
        "Name der MAV": "An Wolf und Kinzig",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-wolfach.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Kirchplatz 6",
        "PLZ": 77709,
        "Ort": "Oberwolfach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 20,
        "Name der MAV": "Haslach",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-haslach.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Kinzigstraße 10",
        "PLZ": 77790,
        "Ort": "Steinach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 20,
        "Name der MAV": "Hausach-Hornberg",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@hausach-hornberg.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Reichenbacher Straße 9",
        "PLZ": 78132,
        "Ort": "Hornberg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 20,
        "Name der MAV": "Oberes Wolftal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-oberes-wolftal.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Wolftalstraße 36",
        "PLZ": 77776,
        "Ort": "Bad Rippoldsau-Schapbach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 21,
        "Name der MAV": "Ettenheim",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "MAV@kath-ettenheim.de",
        "DekanatNr": "Lahr",
        "Strasse": "Schulstraße 5",
        "PLZ": 77955,
        "Ort": "Ettenheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 21,
        "Name der MAV": "Kippenheim Maria Frieden",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@mariafrieden-kippenheim.de",
        "DekanatNr": "Lahr",
        "Strasse": "Bahnhofstraße 36",
        "PLZ": 77971,
        "Ort": "Kippenheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 21,
        "Name der MAV": "Kirche an der Schutter",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-schutter.de",
        "DekanatNr": "Lahr",
        "Strasse": "Bergstraße 32",
        "PLZ": 77978,
        "Ort": "Schuttertal",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 21,
        "Name der MAV": "Rust",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@se-rust.de",
        "DekanatNr": "Lahr",
        "Strasse": "Neuritti 3",
        "PLZ": 77966,
        "Ort": "Kappel-Grafenhausen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 22,
        "Name der MAV": "Am Litzelberg",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@am-litzelberg.de",
        "DekanatNr": "Endingen-Waldkirch",
        "Strasse": "Guldengasse 55",
        "PLZ": 79369,
        "Ort": "Wyhl",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 22,
        "Name der MAV": "An der Glotter",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@an-der-glotter.de",
        "DekanatNr": "Endingen-Waldkirch",
        "Strasse": "Borromäweg 4",
        "PLZ": 79286,
        "Ort": "Glottertal",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 22,
        "Name der MAV": "Emmendingen-Teningen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-emmendingen.de",
        "DekanatNr": "Endingen-Waldkirch",
        "Strasse": "Schillerstraße 16",
        "PLZ": 79312,
        "Ort": "Emmendingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 22,
        "Name der MAV": "Herbolzheim-Rheinhausen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@se-her-rhein.de",
        "DekanatNr": "Endingen-Waldkirch",
        "Strasse": "Feldbergstraße 8",
        "PLZ": 79336,
        "Ort": "Herbolzheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 22,
        "Name der MAV": "Mittleres Elz- und Simonswäldertal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-semes.de",
        "DekanatNr": "Endingen-Waldkirch",
        "Strasse": "Kirchstraße 12",
        "PLZ": 79261,
        "Ort": "Gutach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 22,
        "Name der MAV": "Nördlicher Kaiserstuhl",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@senoka.de",
        "DekanatNr": "Endingen-Waldkirch",
        "Strasse": "Winzerweg 14",
        "PLZ": 79346,
        "Ort": "Endingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 22,
        "Name der MAV": "Oberes Elztal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-oberes-elztal.de",
        "DekanatNr": "Endingen-Waldkirch",
        "Strasse": "Dekan-Seiler-Straße 2",
        "PLZ": 79297,
        "Ort": "Winden",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 22,
        "Name der MAV": "Waldkirch",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-waldkirch@ksew.de",
        "DekanatNr": "Endingen-Waldkirch",
        "Strasse": "Schulstraße 10",
        "PLZ": 79183,
        "Ort": "Waldkirch",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 23,
        "Name der MAV": "Freiburg Mitte",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-freiburg-mitte.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Whittierstraße 17",
        "PLZ": 79106,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 23,
        "Name der MAV": "Freiburg Nord",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-freiburg-nord.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Hauptstraße 46 a",
        "PLZ": 79104,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 23,
        "Name der MAV": "Freiburg Nordwest",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@se-freiburg-nordwest.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Hofackerstraße 35 a",
        "PLZ": 79110,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 23,
        "Name der MAV": "Freiburg Ost",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mavfreiburgost-anke@web.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Scheibenbergweg 5",
        "PLZ": 79117,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 23,
        "Name der MAV": "Freiburg St. Georgen-Hexental",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-geht.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Hartkirchweg 50 a",
        "PLZ": 79111,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 23,
        "Name der MAV": "Freiburg Südwest",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-freiburg-suedwest.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Sulzburger Straße 18",
        "PLZ": 79114,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 23,
        "Name der MAV": "Freiburg-Tuniberg",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-freiburgtuniberg@t-online.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Kaplaneigasse 6",
        "PLZ": 79112,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 23,
        "Name der MAV": "Freiburg-Wiehre-Günterstal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-wiehre-guenterstal.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Schützenallee 19",
        "PLZ": 79102,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 24,
        "Name der MAV": "Batzenberg - Obere Möhlin",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-bom.de",
        "DekanatNr": "Breisach-Neuenburg",
        "Strasse": "Im Brunnacker 9",
        "PLZ": 79227,
        "Ort": "Schallstadt",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 24,
        "Name der MAV": "Breisach - Merdingen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@se-breisach-merdingen.de",
        "DekanatNr": "Breisach-Neuenburg",
        "Strasse": "Friedhofallee 7",
        "PLZ": 79206,
        "Ort": "Breisach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 24,
        "Name der MAV": "Heitersheim",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@seelsorgeeinheit-heitersheim.de",
        "DekanatNr": "Breisach-Neuenburg",
        "Strasse": "Hefegasse 8",
        "PLZ": 79423,
        "Ort": "Heitersheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 24,
        "Name der MAV": "Staufen-St. Trudpert",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-staufen-muenstertal@web.de",
        "DekanatNr": "Breisach-Neuenburg",
        "Strasse": "Rotenbuck 29",
        "PLZ": 79244,
        "Ort": "Münstertal",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 24,
        "Name der MAV": "Vogtsburg",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@seelsorgeeinheit-vogtsburg.de",
        "DekanatNr": "Breisach-Neuenburg",
        "Strasse": "Steingrubenweg 1",
        "PLZ": 79235,
        "Ort": "Vogtsburg im Kaiserstuhl",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 25,
        "Name der MAV": "Beim Titisee",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-se-neustadt@web.de",
        "DekanatNr": "Neustadt",
        "Strasse": "Ahornweg 2",
        "PLZ": 79822,
        "Ort": "Titisee-Neustadt",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 25,
        "Name der MAV": "Dreisamtal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-dreisamtal.de",
        "DekanatNr": "Neustadt",
        "Strasse": "Hauptstraße 7",
        "PLZ": 79254,
        "Ort": "Oberried",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 26,
        "Name der MAV": "Oberes Bregtal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-bregtal.de",
        "DekanatNr": "Schwarzwald-Baar",
        "Strasse": "Lindenstraße 5",
        "PLZ": 78120,
        "Ort": "Furtwangen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 26,
        "Name der MAV": "Triberg Maria in der Tanne",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@pfarrgemeinde-triberg.de",
        "DekanatNr": "Schwarzwald-Baar",
        "Strasse": "Turntalstraße 2",
        "PLZ": 78136,
        "Ort": "Schonach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 27,
        "Name der MAV": "Auf der Baar",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-aufderbaar.de",
        "DekanatNr": "Schwarzwald-Baar",
        "Strasse": "Friedenstraße 9",
        "PLZ": 78183,
        "Ort": "Hüfingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 27,
        "Name der MAV": "Donaueschingen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-ds@kath-donaueschingen.de",
        "DekanatNr": "Schwarzwald-Baar",
        "Strasse": "Schulstraße 13 a",
        "PLZ": 78166,
        "Ort": "Donaueschingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 27,
        "Name der MAV": "Immendingen-Möhringen St. Sebastian",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-immendingen-moehringen.de",
        "DekanatNr": "Hegau",
        "Strasse": "Hindenburgstraße 3",
        "PLZ": 79194,
        "Ort": "Immendingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 28,
        "Name der MAV": "Grenzach-Wyhlen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "",
        "DekanatNr": "Wiesental",
        "Strasse": "Kolpingstraße 2",
        "PLZ": 79639,
        "Ort": "Grenzach-Wyhlen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 28,
        "Name der MAV": "Lörrach-Inzlingen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-kirche-loerrach.de",
        "DekanatNr": "Wiesental",
        "Strasse": "Käppelestraße 18",
        "PLZ": 79540,
        "Ort": "Lörrach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 28,
        "Name der MAV": "Mittleres Wiesental",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav.mittleres.wiesental@gmail.com",
        "DekanatNr": "Wiesental",
        "Strasse": "Adolf-Müller-Straße 10 a",
        "PLZ": 79650,
        "Ort": "Schopfheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 28,
        "Name der MAV": "Oberes Wiesental",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-oberes-wiesental@web.de",
        "DekanatNr": "Wiesental",
        "Strasse": "Luisenstraße 5",
        "PLZ": 79677,
        "Ort": "Schönau",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 28,
        "Name der MAV": "Rheinfelden",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-rheinfelden.de",
        "DekanatNr": "Wiesental",
        "Strasse": "Nollinger Straße 33",
        "PLZ": 79618,
        "Ort": "Rheinfelden",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 28,
        "Name der MAV": "Weil am Rhein",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-weil.de",
        "DekanatNr": "Wiesental",
        "Strasse": "Rudolf-Virchow-Straße 8",
        "PLZ": 79576,
        "Ort": "Weil am Rhein",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 29,
        "Name der MAV": "Bad Säckingen-Murg",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-christina.friese@se-bsm.de",
        "DekanatNr": "Waldshut",
        "Strasse": "Schulstraße 1",
        "PLZ": 79730,
        "Ort": "Murg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 29,
        "Name der MAV": "St. Blasien",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@se-stblasien.de",
        "DekanatNr": "Waldshut",
        "Strasse": "Schulstraße 5",
        "PLZ": 79785,
        "Ort": "Dachsberg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 30,
        "Name der MAV": "Klettgau-Wutöschingen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-sekw.de",
        "DekanatNr": "Waldshut",
        "Strasse": "Degernauer Straße 71",
        "PLZ": 79771,
        "Ort": "Klettgau",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 30,
        "Name der MAV": "Mittlerer Hochrhein St. Verena",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@st-verena.de",
        "DekanatNr": "Waldshut",
        "Strasse": "Kirchplatz 1",
        "PLZ": 79761,
        "Ort": "Waldshut-Tiengen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 30,
        "Name der MAV": "Oberes Schlüchttal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-schluechttal.de",
        "DekanatNr": "Waldshut",
        "Strasse": "Kirchsteig 2",
        "PLZ": 79865,
        "Ort": "Grafenhausen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 31,
        "Name der MAV": "Oberer Hegau",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-oberer-hegau.de",
        "DekanatNr": "Hegau",
        "Strasse": "Langensteiner Straße 2",
        "PLZ": 78267,
        "Ort": "Aach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 31,
        "Name der MAV": "Singen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-singen.de",
        "DekanatNr": "Hegau",
        "Strasse": "Hadwigstraße 27",
        "PLZ": 78224,
        "Ort": "Singen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 32,
        "Name der MAV": "Konstanz-Petershausen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "m.michling@mav.petershausen.net",
        "DekanatNr": "Konstanz",
        "Strasse": "Goebelbeckerstraße 6",
        "PLZ": 78467,
        "Ort": "Konstanz",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 32,
        "Name der MAV": "Wollmatingen-Allensbach",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-wa.de",
        "DekanatNr": "Konstanz",
        "Strasse": "Berchenstraße 48",
        "PLZ": 78467,
        "Ort": "Konstanz",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 33,
        "Name der MAV": "Höri",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav.se.hoeri@t-online.de",
        "DekanatNr": "Hegau",
        "Strasse": "Schulstraße 4",
        "PLZ": 78345,
        "Ort": "Moos-Bankholzen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 33,
        "Name der MAV": "Radolfzell St. Radolt",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-radolt@kath-radolfzell.de",
        "DekanatNr": "Konstanz",
        "Strasse": "Marktplatz 7",
        "PLZ": 78315,
        "Ort": "Radolfzell",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 34,
        "Name der MAV": "Markdorf",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@se-markdorf.de",
        "DekanatNr": "Linzgau",
        "Strasse": "Bahnhofstraße 15",
        "PLZ": 88697,
        "Ort": "Bermatingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 34,
        "Name der MAV": "Meersburg",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-meersburg.de",
        "DekanatNr": "Linzgau",
        "Strasse": "Droste-Hülshof-Weg 10",
        "PLZ": 88709,
        "Ort": "Meersburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 34,
        "Name der MAV": "Salem-Heiligenberg",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-salem.de",
        "DekanatNr": "Linzgau",
        "Strasse": "Kirchstraße 16",
        "PLZ": 88699,
        "Ort": "Frickingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 35,
        "Name der MAV": "Beuron",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "blender.mav@gmail.com",
        "DekanatNr": "Sigmaringen-Meßkirch",
        "Strasse": "Kirchstraße 8",
        "PLZ": 88631,
        "Ort": "Beuron",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 35,
        "Name der MAV": "Gammertingen-Trochtelfingen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-ga-tro.de",
        "DekanatNr": "Sigmaringen-Meßkirch",
        "Strasse": "St. Josef Straße 8",
        "PLZ": 72419,
        "Ort": "Neufra",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 35,
        "Name der MAV": "Heuberg St. Barbara",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-stetten@web.de",
        "DekanatNr": "Sigmaringen-Meßkirch",
        "Strasse": "Ebingerstraße 4",
        "PLZ": 72510,
        "Ort": "Stetten-Frohnstetten",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 35,
        "Name der MAV": "Laiz-Leibertingen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav.laiz-leibertingen@web.de",
        "DekanatNr": "Sigmaringen-Meßkirch",
        "Strasse": "Lindenstraße 9",
        "PLZ": 72488,
        "Ort": "Sigmaringen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 35,
        "Name der MAV": "Sigmaringen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-sigmaringen@web.de",
        "DekanatNr": "Sigmaringen-Meßkirch",
        "Strasse": "Kirchberg 11",
        "PLZ": 72511,
        "Ort": "Bingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 35,
        "Name der MAV": "Straßberg-Veringen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mavstrassbergveringen@web.de",
        "DekanatNr": "Sigmaringen-Meßkirch",
        "Strasse": "Kirchweg 6",
        "PLZ": 72474,
        "Ort": "Winterlingen-Harthausen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 36,
        "Name der MAV": "Burladingen-Jungingen",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-burladingen.de",
        "DekanatNr": "Zollern",
        "Strasse": "Rausse 16",
        "PLZ": 72393,
        "Ort": "Burladingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 36,
        "Name der MAV": "Empfingen-Dießener Tal",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav-empfingen@kath-empfingen.de",
        "DekanatNr": "Zollern",
        "Strasse": "Horber Gäßle 29",
        "PLZ": 72186,
        "Ort": "Empfingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 36,
        "Name der MAV": "Eyachtal - Haigerloch St. Anna",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-haigerloch.de",
        "DekanatNr": "Zollern",
        "Strasse": "Lämmerwiesen 7",
        "PLZ": 72401,
        "Ort": "Haigerloch",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 36,
        "Name der MAV": "Hechingen St. Luzius",
        "Art der MAV": "Kath. Kirchengemeinde",
        "Mail": "mav@kath-hechingen.de",
        "DekanatNr": "Zollern",
        "Strasse": "Firststraße 32",
        "PLZ": 72379,
        "Ort": "Hechingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Allgemeiner Kirchenfonds für Hohenzollern",
        "Art der MAV": "Bildungshaus St. Luzen",
        "Mail": "mav@luzen.de",
        "DekanatNr": "Zollern",
        "Strasse": "Klostersteige 6",
        "PLZ": 72379,
        "Ort": "Hechingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Amt für Kirchenmusik",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "",
        "DekanatNr": "Freiburg",
        "Strasse": "Schoferstraße 2",
        "PLZ": 79098,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Bildungshaus Kloster St. Ulrich e.V.",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "daniel.dombrowsky@bksu.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Haus 10",
        "PLZ": 79283,
        "Ort": "Bollschweil",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Bildungswerk der Erzdiözese Freiburg",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "info@mav-bwerk.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Karlsruher Straße 3",
        "PLZ": 79108,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Collegium Sapientiae\nKath. Hochschulge. Edith Stein",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav-khg-cs-freiburg@khg-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Lorettostraße 24",
        "PLZ": 79100,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Erzb. Bauamt Freiburg",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@erzb-bauamt-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Hildastraße 65",
        "PLZ": 79102,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Erzb. Bauamt Heidelberg",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@erzb-bauamt-heidelberg",
        "DekanatNr": "Heidelberg-Weinheim",
        "Strasse": "Eisenlohrstraße 6",
        "PLZ": 69115,
        "Ort": "Heidelberg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Erzb. Bauamt Konstanz",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@erzb-bauamt-konstanz.de",
        "DekanatNr": "Konstanz",
        "Strasse": "Konzilstraße 7",
        "PLZ": 78462,
        "Ort": "Konstanz",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Erzb. Priesterseminar \nCollegium Borromaeum",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mavcb@cb-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Schoferstraße 1",
        "PLZ": 79098,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Erzbischöfliche Kurie",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav.waldraff@ordinariat-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Schoferstraße 2",
        "PLZ": 79098,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Erzbischöfliches Seelsorgeamt",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@seelsorgeamt-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Okenstraße 15",
        "PLZ": 79108,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Fachbereich 2. Bildungsweg -Bildungwerk",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "info@mav-zweiter-bweg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Hildastraße 39",
        "PLZ": 79102,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Geistliches Zentrum St. Peter",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@geistliches-zentrum.org",
        "DekanatNr": "Neustadt",
        "Strasse": "Klosterhof 2",
        "PLZ": 79271,
        "Ort": "St. Peter",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Gemeindereferentinnen und Gemeindereferenten",
        "Art der MAV": "Sondervertretung",
        "Mail": "info@mav-gref-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Schoferstraße 2",
        "PLZ": 79098,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Geschäftsstelle DiAG/MAV/KODA",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@diag-mav-freiburg.de",
        "DekanatNr": "Endingen-Waldkirch",
        "Strasse": "Carl-Kistner-Straße 51",
        "PLZ": 79115,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Institut für Religionspädagogik",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@irp-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Habsburgerstraße 107",
        "PLZ": 79104,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Jugendzentrum Freizeitstetten e. V.\nFörderband e. V.",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@foerderband-ma.de",
        "DekanatNr": "Mannheim",
        "Strasse": "D 4, 4",
        "PLZ": 68159,
        "Ort": "Mannheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kath. Dekanat Karlsruhe",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "michael.drescher@mav-pref-freiburg.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Erbprinzenstraße 14",
        "PLZ": 76133,
        "Ort": "Karlsruhe",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kath. Fachschule für Sozialpädagogik Agneshaus",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "heil@agneshaus.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Hirschstraße 37 a",
        "PLZ": 76133,
        "Ort": "Karlsruhe",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kath. Fachschule für Sozialpädagogik Buchen",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "MAV@fsp-buchen.de",
        "DekanatNr": "Mosbach-Buchen",
        "Strasse": "St. Rochusstraße 6",
        "PLZ": 7422,
        "Ort": "Buchen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kath. Fachschule für Sozialpädagogik Freiburg",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@kath-fsp-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Händelstraße 10",
        "PLZ": 79104,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kath. Fachschule für Sozialpädagogik Gengenbach",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@fsp-gengenbach.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Parkweg 5",
        "PLZ": 77723,
        "Ort": "Gengenbach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kath. Fachschule für Sozialpädagogik Sancta Maria",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@fsp-sanctamaria.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Hochstraße 2",
        "PLZ": 76646,
        "Ort": "Bruchsal",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kath. Gesamtkirchengemeinde Freiburg",
        "Art der MAV": "GKG",
        "Mail": "mav.gkgfr@gkg-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Jakob-Burckhard-Straße 13",
        "PLZ": 79098,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kath. Gesamtkirchengemeinde Karlsruhe",
        "Art der MAV": "GKG",
        "Mail": "mav@gkg-karlsruhe.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Ständehausstraße 4",
        "PLZ": 76133,
        "Ort": "Karlsruhe",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kath. Gesamtkirchengemeinde Mannheim",
        "Art der MAV": "GKG",
        "Mail": "mav@gkg-mannheim.de",
        "DekanatNr": "Mannheim",
        "Strasse": "A 4, 1",
        "PLZ": 68159,
        "Ort": "Mannheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kath. Hochschulgemeinde Karlsruhe",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "claudia.floetzer@khg-karlsruhe.de",
        "DekanatNr": "Karlsruhe",
        "Strasse": "Hirschstraße 103",
        "PLZ": 76137,
        "Ort": "Karlsruhe",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Katholische Akademie",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@katholische-akademie-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Wintererstraße 1",
        "PLZ": 79104,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kolleg St. Blasien e. V.",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@kolleg-st-blasien.org",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Fürstabt-Gerbert-Straße 14",
        "PLZ": 79837,
        "Ort": "St. Blasien",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Pastoralreferentinnen und Pastoralreferenten",
        "Art der MAV": "Sondervertretung",
        "Mail": "info@mav-pref-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Schoferstraße 2",
        "PLZ": 79098,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Religionslehrerinnen und Religionslehrer",
        "Art der MAV": "Sondervertretung",
        "Mail": "michael.laengle@mav-rl-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Schoferstraße 2",
        "PLZ": 79098,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Schulstiftung der Erzdiözese Freiburg",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "michael.schmidt@schulstiftung-freiburg.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Bismarckallee 14",
        "PLZ": 79098,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "St. Alban Haus\nKath. Studentenwohnheim",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@st-albanhaus.org",
        "DekanatNr": "Freiburg",
        "Strasse": "Reinhold-Schneider-Straße 37",
        "PLZ": 79117,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Stiftungen der Erzdiözese Freiburg\n-Dienststelle Heidelberg-",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "sebastian.nick@schaffnei-hd.de",
        "DekanatNr": "Heidelberg-Weinheim",
        "Strasse": "Eisenlohrstraße 8",
        "PLZ": 69115,
        "Ort": "Heidelberg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Achern",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-achern.de",
        "DekanatNr": "Achern-Renchtal",
        "Strasse": "Kirchstraße 23/1",
        "PLZ": 77855,
        "Ort": "Achern",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Bruchsal",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-bruchsal.de",
        "DekanatNr": "Bruchsal",
        "Strasse": "Wilderichstraße 10",
        "PLZ": 76646,
        "Ort": "Bruchsal",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Bühl",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-buehl.de",
        "DekanatNr": "Baden-Baden",
        "Strasse": "Henri-Dunant-Platz 1",
        "PLZ": 77815,
        "Ort": "Bühl",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Durmersheim",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-durmersheim.de",
        "DekanatNr": "Rastatt",
        "Strasse": "Hauptstraße 145",
        "PLZ": 76448,
        "Ort": "Durmersheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Hechingen",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "diana.pfister@vst-hechingen.de",
        "DekanatNr": "Zollern",
        "Strasse": "Weilheimerstraße 31",
        "PLZ": 72379,
        "Ort": "Hechingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Heidelberg-Weinheim",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "alexandra.mueller@vst-hd-weinheim.de",
        "DekanatNr": "Heidelberg-Weinheim",
        "Strasse": "Im Taubenfeld 25",
        "PLZ": 69123,
        "Ort": "Heidelberg-Wieblingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Heidelberg-Wiesloch",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mathias.dries@vst-hd-wiesloch.de",
        "DekanatNr": "Heidelberg-Weinheim",
        "Strasse": "Am Dorf 13",
        "PLZ": 69124,
        "Ort": "Heidelberg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Lahr",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-lahr.de",
        "DekanatNr": "Lahr",
        "Strasse": "Gutenbergstraße 37",
        "PLZ": 77933,
        "Ort": "Lahr",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Obrigheim",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-obrigheim.de",
        "DekanatNr": "Mosbach-Buchen",
        "Strasse": "Kirchgasse 5",
        "PLZ": 74847,
        "Ort": "Obrigheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Offenburg",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav-vst-offenburg@gmx.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Pfarrstraße 6",
        "PLZ": 77652,
        "Ort": "Offenburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Pforzheim",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-pforzheim.de",
        "DekanatNr": "Pforzheim",
        "Strasse": "Erbprinzenstraße 34",
        "PLZ": 75175,
        "Ort": "Pforzheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Radolfzell",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-radolfzell.de",
        "DekanatNr": "Konstanz",
        "Strasse": "Mezgerwaidring 102",
        "PLZ": 78315,
        "Ort": "Radolfzell",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Rastatt",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-rastatt.de",
        "DekanatNr": "Rastatt",
        "Strasse": "Murgstraße 3",
        "PLZ": 76437,
        "Ort": "Rastatt",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Riegel",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-riegel.de",
        "DekanatNr": "Freiburg",
        "Strasse": "Kirchstraße 9 a",
        "PLZ": 79359,
        "Ort": "Riegel",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Schopfheim",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-schopfheim.de",
        "DekanatNr": "Wiesental",
        "Strasse": "Adolf-Müller-Straße 5 a",
        "PLZ": 79650,
        "Ort": "Schopfheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Sigmaringen",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-sigmaringen.de",
        "DekanatNr": "Sigmaringen-Meßkirch",
        "Strasse": "Gorheimer Straße 28",
        "PLZ": 72488,
        "Ort": "Sigmaringen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Singen",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-singen.de",
        "DekanatNr": "Konstanz",
        "Strasse": "Widerholdstraße 24",
        "PLZ": 78224,
        "Ort": "Singen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Stegen",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-stegen.de",
        "DekanatNr": "Neustadt",
        "Strasse": "Hauptstraße 2 a",
        "PLZ": 79252,
        "Ort": "Stegen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Stühlingen",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-stuehlingen.de",
        "DekanatNr": "Waldshut",
        "Strasse": "Kirchweg 1",
        "PLZ": 79780,
        "Ort": "Stühlingen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Tauberbischofsheim",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-tbb.de",
        "DekanatNr": "Tauberbischofsheim",
        "Strasse": "Krautgartenweg 6",
        "PLZ": 97941,
        "Ort": "Tauberbischofsheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Verrechnungsstelle für kath. Kirchengem. Walldürn",
        "Art der MAV": "Erzdiözese Freiburg",
        "Mail": "mav@vst-wallduern.de",
        "DekanatNr": "Mosbach-Buchen",
        "Strasse": "Dr.-Heinrich-Köhler-Straße 33",
        "PLZ": 74731,
        "Ort": "Walldürn",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Gymnasium St. Paulusheim",
        "Art der MAV": "Schulstiftung",
        "Mail": "mav@paulusheim.de",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Huttenstraße 49",
        "PLZ": 76646,
        "Ort": "Bruchsal",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Heimschule Kloster Wald",
        "Art der MAV": "Schulstiftung",
        "Mail": "philipp.duerr@heimschule-kloster-wald.de",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Von-Weckenstein-Straße 2",
        "PLZ": 88639,
        "Ort": "Wald",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Heimschule Lender",
        "Art der MAV": "Schulstiftung",
        "Mail": "michael.kruebel.mav@heimschule-lender.de",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Friedhofstraße 19",
        "PLZ": 77880,
        "Ort": "Sasbach",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Heimschule St. Landolin",
        "Art der MAV": "Schulstiftung",
        "Mail": "markus.wellinger@hsl.schule",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Prälat-Schofer-Straße 1",
        "PLZ": 77955,
        "Ort": "Ettenheim",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Klosterschule vom hl. Grab",
        "Art der MAV": "Schulstiftung",
        "Mail": "d.riedmann-kress@hl-grab.de",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Römerplatz 9",
        "PLZ": 76530,
        "Ort": "Baden-Baden",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Klosterschulen Unserer lieben Frau",
        "Art der MAV": "Schulstiftung",
        "Mail": "mav@klosterog.de",
        "DekanatNr": "Offenburg-Kinzigtal",
        "Strasse": "Lange Straße 9",
        "PLZ": 77652,
        "Ort": "Offenburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Kolleg St. Sebastian",
        "Art der MAV": "Schulstiftung",
        "Mail": "",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Hauptstraße 4",
        "PLZ": 79252,
        "Ort": "Stegen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Liebfrauenschule Sigmaringen",
        "Art der MAV": "Schulstiftung",
        "Mail": "mav@liebfrauenschule-sigmaringen.de",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Liebfrauenweg 2",
        "PLZ": 72488,
        "Ort": "Sigmaringen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "St. Dominikus Gymnasium",
        "Art der MAV": "Schulstiftung",
        "Mail": "mav@dominikus-gymnasium.de",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Seminarstraße 5",
        "PLZ": 76133,
        "Ort": "Karlsruhe",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "St. Raphael Schulen Heidelberg",
        "Art der MAV": "Schulstiftung",
        "Mail": "mav@raph-hd.de",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Roonstraße 1-5",
        "PLZ": 69120,
        "Ort": "Heidelberg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "St. Ursula Gymnasium",
        "Art der MAV": "Schulstiftung",
        "Mail": "gompel@st-ursula-freiburg.de",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Eisenbahnstraße 45",
        "PLZ": 79098,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "St. Ursula Schulen",
        "Art der MAV": "Schulstiftung",
        "Mail": "annette.sumbert@ursula-wiehre.de",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Hildastraße 41",
        "PLZ": 79102,
        "Ort": "Freiburg",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "St. Ursula Schulen Villingen",
        "Art der MAV": "Schulstiftung",
        "Mail": "sekretariat@stu-vs.de",
        "DekanatNr": "Schulstiftung",
        "Strasse": "Bickenstraße 25",
        "PLZ": 78050,
        "Ort": "Villingen-Schwenningen",
        "": "",
        "__1": "",
        "__2": ""
    },
    {
        "KG neu": 37,
        "Name der MAV": "Ursulinen Gymnasium",
        "Art der MAV": "Schulstiftung",
        "Mail": "putzier@ursulinen-gymnasium.de",
        "DekanatNr": "Schulstiftung",
        "Strasse": "A4 4",
        "PLZ": 68159,
        "Ort": "Mannheim",
        "": "",
        "__1": "",
        "__2": ""
    }
];