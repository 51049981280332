import { LatLngExpression } from "leaflet"
import { gemeindeColorList } from "./GemeindeColors"

export type MavType = "Schulstiftung" | "Erzdiözese Freiburg" | "Kath. Kirchengemeinde" | "Unknown"
export const AllMavTypes: MavType[] = ["Schulstiftung", "Erzdiözese Freiburg", "Kath. Kirchengemeinde", "Unknown"]

export const MavTypeColor = (kirchengemeinde: string): string => {
    return gemeindeColorList[+kirchengemeinde]
}

export const MavTypeDisplayName = (type: MavType): string => {
    switch (type) {
        case "Schulstiftung":
            return "Schulstiftung"
        case "Erzdiözese Freiburg":
            return "Erzdiözese Freiburg"
        case "Kath. Kirchengemeinde":
            return "Kath. Kirchengemeinde"
        case "Unknown":
            return "Andere"
    }
}

export type MavLocation = {
    id: string
    position: LatLngExpression,
    name: string,
    address: string,
    email?: string,
    kirchengemeinde: string,
    mavtype: MavType
}

export type Address = {
    Street: string,
    HouseNbr: string,
    PostalCode: string,
    City: string
}

export const AddressToString = (address: Address) => {
    return `${address.Street} ${address.HouseNbr}, ${address.PostalCode} ${address.City}`
}

const EmptyAddress: Address = {
    City: '',
    HouseNbr: '',
    PostalCode: '',
    Street: ''
}

export const StringToAddress = (str: string): Address => {
    if (str.trim() === '') return EmptyAddress

    const s1 = str.split(', ')
    const s2 = s1[0].split(' ')
    const s3 = s1[1].split(' ')

    return {
        Street: s2[0] ?? '',
        HouseNbr: s2[1] ?? '',
        PostalCode: s3[0] ?? '',
        City: s3[1] ?? ''
    }
}