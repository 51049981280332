import { GetAuthCode } from "../EditMode";
import { MavLocation } from "../dtos/MavLocation";

const apiUrl = 'https://mav.blackforestsentinel.de/API.php';

const createNewMavLocation = async (newMavLocation: MavLocation): Promise<MavLocation> => {
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': GetAuthCode() ?? ''
        },
        body: JSON.stringify(newMavLocation)
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return { ...newMavLocation, id: data.id };
}

const deleteMavLocation = async (toBeDeletedId: string): Promise<string> => {
    const response = await fetch(`${apiUrl}?id=${toBeDeletedId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': GetAuthCode() ?? ''
        }
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data.message === 'Location deleted' ? toBeDeletedId : '';
}

const updateMavLocation = async (updatedMavLocation: MavLocation): Promise<MavLocation> => {
    const response = await fetch(`${apiUrl}?id=${updatedMavLocation.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': GetAuthCode() ?? ''
        },
        body: JSON.stringify(updatedMavLocation)
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    return (await response.json()).data;
}

const getMavLocations = async (): Promise<MavLocation[]> => {
    const response = await fetch(apiUrl, {
        method: 'GET'
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
}

export const backend = {
    createNewMavLocation,
    deleteMavLocation,
    updateMavLocation,
    getMavLocations
}