import { FC } from "react"

type FormsInlineTextBoxProps = {
    id: string
    lableValue: string,
    currentValue: string,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    required?: boolean
}

export const FormsInlineTextBox : FC<FormsInlineTextBoxProps> = ({id, currentValue, handleChange, required}) => {

    return (
        <input type="text"
            name={id}
            id={id}
            className="block w-full min-w-60 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={currentValue}
            onChange={handleChange}
            required={required ?? false}
        />
    )
}