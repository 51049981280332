import { useState } from 'react';
import './App.css';
import { IsInEditMode } from './EditMode';
import { getInits } from './Init';
import { MAVSearch } from './components/MAVSearch';
import { MavLocationForm } from './components/MavLocationForm';
import { MavLocationMap } from './components/MavLocationMap';
import { Heading } from './components/Util/Heading';
import { CreateMavLocationPayload } from './dtos/CreateMavLocationPayload';
import { useCreateMavLocationMutation } from './store/MavLocationMutations';

function App() {
  const createMavLocation = useCreateMavLocationMutation()
  const [mapRef, setMapRef] = useState<any>(null)
  const createLocationForm = (
    <div className='pl-10 pt-10 pr-10'>
      <Heading text="Neuer Standort" />
      <MavLocationForm
        data={{ name: '', address: '', kirchengemeinde: '', mavtype: 'Erzdiözese Freiburg' }}
        onSubmit={async (data) => { await createMavLocation.mutateAsync(data) }}
      />
      <button hidden onClick={async () => {
        const data = await getInits()
        const errors: CreateMavLocationPayload[] = []
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          try {
            console.log(i, 'of', data.length)
            await createMavLocation.mutateAsync(element)
          } catch (error) {
            errors.push(element)
            console.error(error)
          }
        }
        console.log('failed...', errors)
      }}>Init!</button>
    </div>
  )

  return (
    <div className="App">
      <MAVSearch mapRef={mapRef} />
      <MavLocationMap setMapRef={setMapRef} />
      {IsInEditMode() && createLocationForm}
    </div>
  );
}

export default App;
