import { FC } from "react"
import { IconType } from "../dtos/IconTypes"
import { faCity, faEnvelope, faGlobe, faHouse, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from 'react-tooltip'

type IcoProps = {
    type: IconType,
    id: string
}

const getDataByType = (type: IconType) => {
    switch (type) {
        case "Address":
            return { icon: faHouse, toolTip: "Addresse" }
        case "Phone":
            return { icon: faPhone, toolTip: "Telefonnummer" }
        case "EMail":
            return { icon: faEnvelope, toolTip: "E-Mail" }
        case "Gemeinde":
            return { icon: faCity, toolTip: "Gemeinde" }
        case "Großgemeinde":
            return { icon: faGlobe, toolTip: "Großgemeinde" }
        default:
            throw new Error("Argument out of range")
    }
}

export const Ico: FC<IcoProps> = ({ type, id }) => {

    const { icon, toolTip } = getDataByType(type)

    return (
        <>
            <Tooltip id={"tooltip-" + id} />
            <FontAwesomeIcon
                tabIndex={-1}
                data-tooltip-id={"tooltip-" + id}
                data-tooltip-content={toolTip}
                data-tooltip-place="top"
                icon={icon} className="mr-3"
            />
        </>
    )
}