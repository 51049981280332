import { useMutation, useQueryClient } from "@tanstack/react-query"
import { MavLocation } from "../dtos/MavLocation"
import { integrateCreateMavLocation, integrateDeleteMavLocation, integrateUpdateMavLocation } from "./MavLocationIntegrations"
import { LatLngExpression } from "leaflet"
import { backend } from "../backend/Backend"
import { CreateMavLocationPayload } from "../dtos/CreateMavLocationPayload"
import { useMavLocations } from "./MavLocationQuerys"


export const useCreateMavLocationMutation = () => {
    const queryClient = useQueryClient()

    const createMavLocationMutation = useMutation<MavLocation, void, CreateMavLocationPayload>({
        mutationFn: createMavLocation,
        onSuccess: integrateCreateMavLocation(queryClient)
    })

    return createMavLocationMutation
}

export const useUpdateMavLocationMutation = () => {
    const queryClient = useQueryClient()
    const { mavLocations } = useMavLocations()

    const createMavLocationMutation = useMutation<MavLocation, void, MavLocation>({
        mutationFn: updateMavLocation(mavLocations ?? []),
        onSuccess: integrateUpdateMavLocation(queryClient)
    })

    return createMavLocationMutation
}

export const useDeleteMavLocationMutation = () => {
    const queryClient = useQueryClient()

    const deleteMavLocationMutation = useMutation<string, void, string>({
        mutationFn: async (toBeDeletedId: string) => await backend.deleteMavLocation(toBeDeletedId),
        onSuccess: integrateDeleteMavLocation(queryClient)
    })

    return deleteMavLocationMutation
}

const createMavLocation = async (payload: CreateMavLocationPayload): Promise<MavLocation> => {

    const coords = await fetchCoordsOfAddress(payload.address)
    const newMavLocation = {
        id: 'will be set by backend',
        ...payload,
        position: coords
    }

    return await backend.createNewMavLocation(newMavLocation)
}

const updateMavLocation = (oldLocations: MavLocation[]) => async (updatedMavLocation: MavLocation): Promise<MavLocation> => {

    const filtered = oldLocations.filter(loc => loc.id === updatedMavLocation.id)
    const oldLocation = filtered.length === 1 ? filtered[0] : null
    let position = updatedMavLocation.position

    if (oldLocation === null || oldLocation.address !== updatedMavLocation.address) {
        position = await fetchCoordsOfAddress(updatedMavLocation.address)
    }

    const cleanedItem = {
        ...updatedMavLocation,
        email: updatedMavLocation.email?.replace(' ', '') === '' ? undefined : updatedMavLocation.email,
        position: position,
    }

    return await backend.updateMavLocation(cleanedItem)
}

const fetchCoordsOfAddress = async (address: string): Promise<LatLngExpression> => {
    try {
        const url = "https://nominatim.openstreetmap.org/search?format=json&q=" + encodeURIComponent(address)
        const response = await fetch(url)
        const error = new Error('Could not find the Address')

        if (!response.ok) {
            console.error(error)
            throw error
        }

        const data = await response.json()

        if (data.length === 0) {
            console.error(error)
            throw error
        }

        return { lat: data[0].lat, lng: data[0].lon }
    } catch (error) {
        alert("Leider konnte die Adresse nicht gefunden werden.")
        throw error;
    }
}
