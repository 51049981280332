import { QueryClient } from "@tanstack/react-query";
import { MavLocation } from "../dtos/MavLocation";
import { mavLocationsQueryKey } from "./MavLocationQuerys";

export const integrateCreateMavLocation = (queryClient: QueryClient) => (mavLocation: MavLocation) => {
    queryClient.setQueryData([mavLocationsQueryKey], (oldData? : MavLocation[]) => [...(oldData ?? []), mavLocation])
}

export const integrateDeleteMavLocation = (queryClient: QueryClient) => (toBeDeletedId: string) => {
    queryClient.setQueryData([mavLocationsQueryKey], (oldData? : MavLocation[]) => (oldData ?? []).filter(i => i.id !== toBeDeletedId))
}

export const integrateUpdateMavLocation = (queryClient: QueryClient) => (updatedMavLocation: MavLocation) => {
    queryClient.setQueryData([mavLocationsQueryKey], (oldData? : MavLocation[]) => [...(oldData ?? []).filter(i => i.id !== updatedMavLocation.id), updatedMavLocation])
}