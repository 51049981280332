import { FC, useState } from "react"
import { FormsTextBox } from "./Util/FormsTextBox"
import { CreateMavLocationPayload } from "../dtos/CreateMavLocationPayload"
import { Btn } from "./Util/Btn"
import { Ico } from "./Ico"
import { MavTypeSelector } from "./MavTypeSelector"
import { FormsAddressBox } from "./Util/FormAddressBox"

type MavLocationFormProps = {
    data: CreateMavLocationPayload,
    onSubmit: (data: CreateMavLocationPayload) => Promise<void>
}


export const MavLocationForm: FC<MavLocationFormProps> = ({ data, onSubmit }) => {
    const [formData, setFormData] = useState<CreateMavLocationPayload>({
        ...data
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData((prevData) => ({ ...prevData, [name]: value }))
    }

    const handleAddressChange = (newAddress: string) => {
        setFormData((prevData) => ({ ...prevData, address: newAddress }))
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        try {
            await onSubmit(formData)
            setFormData({ name: '', address: '', email: undefined, kirchengemeinde: '', mavtype: "Erzdiözese Freiburg" })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <FormsTextBox id="name" lableValue="Standortname" currentValue={formData.name} handleChange={handleChange} required />
            <MavTypeSelector onValueChanged={(mavtype) => setFormData(data => ({ ...data, mavtype: mavtype }))} />
            <FormsTextBox id="kirchengemeinde" lableValue="Neue Kirchengemeinde" currentValue={formData.kirchengemeinde} handleChange={handleChange} required icon={<Ico type="Gemeinde" id="Create-Gemeinde" />} />
            <FormsAddressBox id="address" lableValue="Adresse" currentValue={formData.address} handleChange={handleAddressChange} required icon={<Ico type="Address" id="Create-Address" />} />
            <FormsTextBox id="email" lableValue="E-Mail" currentValue={formData.email ?? ''} handleChange={handleChange} icon={<Ico type="EMail" id="Create-Email" />} />
            <Btn text="Anlegen" type="submit" />
        </form>
    )
}