import { FC, useState } from "react"
import { Address, AddressToString, StringToAddress } from "../../dtos/MavLocation"

type FormsTextBoxProps = {
    id: string
    lableValue: string,
    currentValue: string,
    handleChange: (newValue: string) => void,
    required?: boolean,
    icon?: JSX.Element
}

export const FormsAddressBox: FC<FormsTextBoxProps> = ({ id, lableValue, currentValue, handleChange, required, icon }) => {

    const currentAddress = StringToAddress(currentValue)

    const [startWith, setStartWith] = useState<string>(currentValue)
    const [formData, setFormData] = useState<Address>(currentAddress)

    const intHandleChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
        const { name, value } = e.target
        setFormData((prevData) => {
            const newData = { ...prevData, [name]: value }
            handleChange(AddressToString(newData))
            return newData
        })
    }

    if (currentValue !== startWith) {
        setStartWith(currentValue)
        setFormData(currentAddress)
    }

    return (
        <div className="mt-4">
            <div className="flex flex-row">
                {icon ?? <></>}<label htmlFor={id} className="block text-sm font-semibold leading-6 text-gray-900">{lableValue}</label>
            </div>
            <div className="mt-2.5">
                <div className="flex flex-row">
                    <input type="text"
                        name='Street'
                        id={id}
                        className="basis-4/5 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.Street}
                        onChange={intHandleChange}
                        placeholder="Straße"
                        required={required ?? false}
                    />
                    <input type="text"
                        name='HouseNbr'
                        id={id}
                        className="basis-1/5 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.HouseNbr}
                        onChange={intHandleChange}
                        placeholder="Nr."
                        required={required ?? false}
                    />
                </div>
                <div className="flex flex-row">
                    <input type="text"
                        name='PostalCode'
                        id={id}
                        className="basis-2/5 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.PostalCode}
                        onChange={intHandleChange}
                        required={required ?? false}
                        placeholder="Postleitzahl"
                    />
                    <input type="text"
                        name='City'
                        id={id}
                        className="basis-3/5 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.City}
                        onChange={intHandleChange}
                        placeholder="Stadt"
                        required={required ?? false}
                    />
                </div>
            </div>
        </div>
    )
}