
const calcColorHexes = (amount: number) => {
    const colors: string[] = [];
    const max = Number("0xFFFFFF");
    const min = Number("0x000000");

    const step = (max - min) / (amount - 1);

    for (let i = 0; i < amount; i++) {
        const color = Math.round(i * step).toString(16)
        colors.push('#' + color.padStart(6, '0').toUpperCase());
    }

    return colors;
}

export const gemeindeColorList: string[] = calcColorHexes(40);

const backup = [
    "yellow",
    "gray",
    "#808080",
    "darkolivegreen",
    "#556b2f",
    "sienna",
    "#a0522d",
    "forestgreen",
    "#228b22",
    "darkred",
    "#8b0000",
    "olive",
    "#808000",
    "darkslateblue",
    "#483d8b",
    "teal",
    "#008080",
    "steelblue",
    "#4682b4",
    "navy",
    "#000080",
    "yellowgreen",
    "#9acd32",
    "goldenrod",
    "#daa520",
    "purple2",
    "#7f007f",
    "darkseagreen",
    "#8fbc8f",
    "maroon3",
    "#b03060",
    "tan",
    "#d2b48c",
    "red",
    "#ff0000",
    "darkturquoise",
    "#00ced1",
    "darkorange",
    "#ff8c00",
    "lawngreen",
    "#7cfc00",
    "springgreen",
    "#00ff7f",
    "crimson",
    "#dc143c",
    "mediumpurple",
    "#9370db",
    "blue",
    "#0000ff",
    "purple3",
    "#a020f0",
    "fuchsia",
    "#ff00ff",
    "dodgerblue",
    "#1e90ff",
    "khaki",
    "#f0e68c",
    "laserlemon",
    "#ffff54",
    "plum",
    "#dda0dd",
    "lightblue",
    "#add8e6",
    "deeppink",
    "#ff1493",
    "lightsalmon",
    "#ffa07a",
    "violet",
    "#ee82ee",
    "palegreen",
    "#98fb98",
    "aquamarine",
    "#7fffd4",
    "lightpink",
    "#ffb6c1"
]
