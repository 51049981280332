import { FC } from "react"

type FormsTextBoxProps = {
    id: string
    lableValue: string,
    currentValue: string,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    required?: boolean,
    icon?: JSX.Element
}

export const FormsTextBox: FC<FormsTextBoxProps> = ({ id, lableValue, currentValue, handleChange, required, icon }) => {

    return (
        <div className="mt-4">
            <div className="flex flex-row">
                {icon ?? <></>}<label htmlFor={id} tabIndex={-1} className="block text-sm font-semibold leading-6 text-gray-900">{lableValue}</label>
            </div>
            <div className="mt-2.5">
                <input type="text"
                    name={id}
                    id={id}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={currentValue}
                    onChange={handleChange}
                    required={required ?? false}
                />
            </div>
        </div>
    )
}