import { FC, MutableRefObject, useState } from "react"
import { useMavLocations } from "../store/MavLocationQuerys";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons"

export const MAVSearch: FC<{ mapRef: MutableRefObject<any> }> = ({ mapRef }) => {
    const { mavLocations } = useMavLocations()
    const [show, setShow] = useState(false)

    const highlightById = (id: string) => {
        const mav = mavLocations.find(m => m.id === id)
        const element = document.getElementById('mav-location' + id)?.parentElement
        mapRef.current.flyTo(mav?.position, 13)
        setTimeout(() => element?.click(), 2000);
    }

    return (
        <div>
            <FontAwesomeIcon icon={faSearch} className="mx-3 pt-5 pb-2" onClick={() => setShow(s => !s)} />
            {show ? <div className="w-96">
                <Select
                    placeholder='MAV-Standort suchen'
                    options={mavLocations.map(i => ({ value: i.id, label: i.name }))}
                    onChange={(e: any) => highlightById(e.value)}
                />
            </div>
                : <></>}
        </div>
    )
}
