import { FC, MutableRefObject, useEffect, useRef } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { useMavLocations } from "../store/MavLocationQuerys";
import { MavLocationMarker } from "./MavLocationMarker";
import 'leaflet/dist/leaflet.css';
import { LatLngLiteral } from "leaflet";
import { MavTypeColor } from "../dtos/MavLocation";

export const MavLocationMap: FC<{ setMapRef: (mapRef: MutableRefObject<any>) => void }> = ({ setMapRef }) => {
  const { mavLocations } = useMavLocations()
  const startPosition: LatLngLiteral = { lat: 48.436957, lng: 8.219617 }
  const mapRef = useRef<any>(null);

  useEffect(() => {
    setMapRef(mapRef)
  }, [setMapRef])

  return (
    <MapContainer center={startPosition} zoom={9} scrollWheelZoom={true} ref={mapRef}>
      <TileLayer
        attribution='MAV-Standorte'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {mavLocations.map(location =>
        <MavLocationMarker
          mapRef={mapRef}
          mavLocation={location}
          color={MavTypeColor(location.kirchengemeinde)} key={location.id}
        />
      )}
    </MapContainer>
  );
} 