import { data } from "./data"
import { CreateMavLocationPayload } from "./dtos/CreateMavLocationPayload"
import { MavType } from "./dtos/MavLocation"

export const getInits = async () => {

    return data.map(x => {
        return {
            name: x["Name der MAV"],
            kirchengemeinde: x["KG neu"].toString(),
            mavtype: typeStringToType(x["Art der MAV"]),
            email: x.Mail,
            address: `${x.Strasse}, ${x.PLZ} ${x.Ort}`
        } as CreateMavLocationPayload
    })
}

const typeStringToType = (type: string): MavType => {
    switch (type) {
        case "Schulstiftung":
            return "Schulstiftung"
        case "Kath. Kirchengemeinde":
            return "Kath. Kirchengemeinde"
        case "Erzdiözese Freiburg":
            return "Erzdiözese Freiburg"
        default:
            return "Unknown"
    }
}