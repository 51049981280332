import { ChangeEventHandler, FC } from "react";
import { AllMavTypes, MavType, MavTypeDisplayName } from "../dtos/MavLocation";

type MavTypeSelectorProps = {
    onValueChanged: (selected: MavType) => void,
    currentValue?: MavType
}

export const MavTypeSelector: FC<MavTypeSelectorProps> = ({onValueChanged, currentValue}) => {
    
    const onChange : ChangeEventHandler<HTMLSelectElement> = (e) => {    
        onValueChanged(e.target.value as MavType)
    }
    
    return (
        <select defaultValue={currentValue} onChange={onChange} className="border mt-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            {AllMavTypes.map(type => <option key={type} value={type}>{MavTypeDisplayName(type)}</option>)}
        </select>
    )
}