import { FC } from "react"

type SubmitBtnProps = {
    text: string,
    type?: "submit" | "reset" | "button" | undefined,
    slim?: boolean
    onClick?: () => void
}

export const Btn : FC<SubmitBtnProps> = ({text, type, onClick, slim}) => {

    return (
        <div className="mt-2">
            <button
                style={{paddingBlock: slim === true ? 2 : ''}}
                onClick={() => {if(onClick) onClick()}}
                type={type ?? "button"}
                className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                {text}
            </button>
        </div>
    )
}