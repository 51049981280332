import { MavLocation } from "../dtos/MavLocation";
import { useQuery } from "@tanstack/react-query";
import { backend } from "../backend/Backend";

export const mavLocationsQueryKey = "mavLocations"
export const mavGemeindeColorQueryKey = "mavGemeindeColors"

export const useMavLocations = () => {

    const { data: mavLocations } = useQuery({
        queryKey: [mavLocationsQueryKey],
        queryFn: getMavLocations
    })

    return { mavLocations: mavLocations ?? [] }
}

const getMavLocations = async () : Promise<MavLocation[]> => await backend.getMavLocations()